import { ParagraphProps } from "components/paragraph";

import { ContactHero } from '@/organisms';
import { getUrlFromMedia } from "@/utils/utils";

export function WebformBlock({ paragraph }: ParagraphProps) {
    return (
        <ContactHero
            desciption={paragraph.field_text}
            emailLabel="Email*"
            image={{
                src: getUrlFromMedia(paragraph.field_image),
            }}
            labelCkeckDown="Acepto recibir información comercial."
            surnameLabel="Apellidos*"
            messageLabel="Mensaje*"
            nameLabel="Nombre*"
            pretitle="contacto"
            textButton="enviar"
            title={paragraph.field_title}
            titleForm='Rellene el formulario'
            className="md:-mb-8 z-[5]"
            classNameWrapper="pt-header"
        />
    )
}


