import { FunctionComponent } from 'react';
import { oneLine } from 'common-tags';
import { TypeMoreInfoProps } from './types';
import { ParseText } from '@/utils/parseText';

export const MoreInfo: FunctionComponent<TypeMoreInfoProps> = ({
  className = '',
  title,
  font,
  children,
}: TypeMoreInfoProps) => (
  <div className={`pt-12 pr-2 pb-16 ${className}`}>
    <span
      className={oneLine`
        text-text-primary
        ${font === 'headline'
          ? 'text-headline text-headline--h2'
          : 'text-subtitle text-subtitle--l'
        }
      `}
    >
      {title}
    </span>

    <div
      className="prose min-w-full text-body text-[large] md:text-body--l md:leading-[28px] text-text-primary mt-6 md:gap-32 md:columns-2 md:mt-4" >
      {typeof children === 'string'
        ? <ParseText html={children} />
        : children
      }
    </div>
  </div>
);

MoreInfo.displayName = 'MoreInfo';
